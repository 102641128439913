import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "Layout" */ '../layout'),
    redirect: '/list',
    children: [
      {
        path: '/list',
        name: 'samplingRecordList',
        component: () => import(/* webpackChunkName: "List" */ '../views/Module_DataTransRecordManage')
      },
      // {
      //   path: '/userManage',
      //   name: 'userManage',
      //   component: () => import(/* webpackChunkName: "userManage" */ '../views/Moudle_UserManage')
      // },
      {
        path: '/implantManage/userManage',
        name: 'userManage',
        component: () => import(/* webpackChunkName: "userManage" */ '../views/Moudle_UserManage2'),
        meta: {
          keepAlive: true // 设置为 true 表示需要缓存
        }
      },
      {
        path: '/implantManage/userManage/userDetails',
        name: 'userDetails',
        component: () => import(/* webpackChunkName: "userDetails" */ '../views/Moudle_UserManage2/userDetails')
      },
      {
        path: '/implantManage/userManage/patientDetails',
        name: 'patientDetails',
        component: () => import(/* webpackChunkName: "patientDetails" */ '../views/Moudle_UserManage2/patientDetails')
      },
      {
        path: '/bluetoothGatewayRecord',
        name: 'bluetoothGatewayRecord',
        component: () => import(/* webpackChunkName: "bluetoothGatewayRecord" */ '../views/Moudle_BluetoothGatewayRecord')
      },
      {
        path: '',
        name: 'deviceManage'
      },
      {
        path: '/implantManage/receiptManage',
        name: 'receiptManage',
        component: () => import(/* webpackChunkName: "receiptManage" */ '../views/Module_ReceiptManage')
      },
      {
        path: '/implantManage/receiptManage/details',
        name: 'receiptDetails',
        component: () => import(/* webpackChunkName: "receiptDetails" */ '../views/Module_ReceiptManage/receiptDetails')
      },
      {
        path: '/deviceManage/ICMDeviceManage',
        name: 'ICMDeviceManage',
        component: () => import(/* webpackChunkName: "ICMDeviceManage" */ '../views/Module_DeviceManage/ICMDeviceManage/index.vue')
      },
      {
        path: '/deviceManage/addDevice',
        name: 'addDevice',
        component: () => import(/* webpackChunkName: "ICMDeviceManage" */ '../views/Module_DeviceManage/ICMDeviceManage/addDevice')
      },
      {
        path: '/deviceManage/productModelManage',
        name: 'productModelManage',
        component: () => import(/* webpackChunkName: "productModelManage" */ '../views/Module_DeviceManage/productModelManage/index.vue')
      },
      {
        path: '/systemConfig/firmwareManage',
        name: 'firmwareManage',
        component: () => import(/* webpackChunkName: "firmwareManage" */ '../views/Module_DeviceManage/firmwareManage/index.vue')
      },
      {
        path: '/systemConfig/firmwareManage/uploadFirmware',
        name: 'uploadFirmware',
        component: () => import(/* webpackChunkName: "uploadFirmware" */ '../views/Module_DeviceManage/firmwareManage/uploadFirmware')
      },
      {
        path: '/deviceManage/homeMonitor',
        name: 'homeMonitor',
        component: () => import(/* webpackChunkName: "homeMonitor" */ '../views/Module_DeviceManage/homeMonitor')
      },
      {
        path: '/deviceManage/homeMonitor/add',
        name: 'homeMonitorAdd',
        component: () => import(/* webpackChunkName: "homeMonitorAdd" */ '../views/Module_DeviceManage/homeMonitor/addDevice')
      },
      {
        path: '/systemConfig/firmwareManage/editFirmware',
        name: 'editFirmware',
        component: () => import(/* webpackChunkName: "editFirmware" */ '../views/Module_DeviceManage/firmwareManage/editFirmware')
      },
      {
        path: '/systemConfig/hospitalManage',
        name: 'hospitalManage',
        component: () => import(/* webpackChunkName: "hospitalManage" */ '../views/Moudle_HospitalManage')
      },
      {
        path: '/systemConfig/hospitalManage/addDoctor',
        name: 'addDoctor',
        component: () => import(/* webpackChunkName: "addDoctor" */ '../views/Moudle_HospitalManage/DoctorManage/addDoctor.vue')
      },
      {
        path: '/systemConfig/staffManage',
        name: 'staffManage',
        component: () => import(/* webpackChunkName: "staffManage" */ '../views/Module_StaffManage')
      },
      {
        path: '/implantManage/feedBack',
        name: 'feedBack',
        component: () => import(/* webpackChunkName: "feedBack" */ '../views/Module_FeedBack')
      },
      {
        path: '/systemConfig/deviceOperateLogs',
        name: 'deviceOperateLogs',
        component: () => import(/* webpackChunkName: "deviceOperateLogs" */ '../views/Module_DeviceManage/deviceOperateLog/index.vue')
      },
      {
        path: '/implantManage/icmEvents',
        name: 'icmEvents',
        component: () => import(/* webpackChunkName: "deviceOperateLogs" */ '../views/Module_ImplantMange/icmEvents/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
